import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import HeaderBack from '../components/header-back';
import CategoryInfo from '../components/category-info';

const Contacts = () => (
  <Layout>
    <Helmet>
      <title>
        Контакты собраний в Украине (Запорожье, Мукачево и другие города)
      </title>
      <meta
        name="description"
        content="Адреса собраний братьев во имя Господа Иисуса в Украине: Запорожье, Мукачево, Пятихатки."
      />
      <meta
        name="keywords"
        content="собрание, церковь, Матфея 18:20, Украина, Запорожье, Мукачево, Пятихатки"
      />
    </Helmet>
    <HeaderBack small variant="simple" className="">
      <h1 className="page-title">Контакты</h1>
      <p className="page-description">
        Приходите на собрание в ближайшем к вам городе.
      </p>
    </HeaderBack>
    <div className="content">
      <div className="container">
        <CategoryInfo>
          <p>
            Пожалуйста, по возможности, заранее сообщите нам о Вашем желании
            прийти.
          </p>
        </CategoryInfo>
        <div className="grid-view">
          <div className="row">
            <div className="col-md-4">
              <div className="grid-view-elem">
                <h3 className="grid-view-element-title">Запорожье</h3>
                <div className="grid-view-elem-description">
                  <p>
                    <strong>ул. Володарская, д. 20</strong> <br />
                    (как добраться см. на{' '}
                    <a
                      href="https://www.google.com/maps/dir/''/%D1%83%D0%BB%D0%B8%D1%86%D0%B0+%D0%92%D0%BE%D0%BB%D0%BE%D0%B4%D0%B0%D1%80%D1%81%D0%BA%D0%B0%D1%8F,+20,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D0%BE%D0%B6%D1%8C%D0%B5,+%D0%97%D0%B0%D0%BF%D0%BE%D1%80%D0%BE%D0%B6%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C/@47.8352343,35.1562523,16z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x40dc6754fc4e5d2b:0x1ba36ae6ea458b84!2m2!1d35.1595589!2d47.830386!1m5!1m1!1s0x40dc6750a75bfe93:0xebbdeec800de6680!2m2!1d35.1636571!2d47.837837!3e0?hl=en-US"
                      target="_blank"
                      rel="noreferrer nofollow">
                      карте
                    </a>
                    )
                  </p>
                  <p>
                    Собрание назидания:{' '}
                    <span className="color grey">
                      по воскресениям 12:30-14:00
                    </span>
                    <br />
                    Молитвенное и разбор слова:{' '}
                    <span className="color grey">по средам 18:00-20:00</span>
                  </p>
                  <p>
                    Контакты брата Михаила
                    <br />
                    <a href="tel:+380679623971">+380 (67) 962-39-71</a>
                    <br />
                    <a href="tel:+380632399848">+380 (63) 239-98-48</a>
                    <br />
                    <a href="mailto:micha.tkatchenko (@) gmail.com">
                      micha.tkatchenko (@) gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="grid-view-elem">
                <h3 className="grid-view-element-title">Мукачево</h3>
                <p className="grid-view-elem-description">
                  <p>
                    <strong>
                      ул. Григория Сковороды (ранее Шандора Пака), д. 14
                    </strong>
                  </p>
                  <p>
                    Собрание назидания:{' '}
                    <span className="color grey">
                      по воскресениям 12:00-13:00
                    </span>
                    <br />
                    Молитвенное и разбор слова:{' '}
                    <span className="color grey">по четвергам 18:00-20:00</span>
                  </p>
                  <p>
                    Контакты брата Владимира
                    <br />
                    <a href="tel:+380505923679">+380 (50) 592-36-79</a>
                    <br />
                    &nbsp;
                    <br />
                    &nbsp;
                  </p>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="grid-view-elem">
                <h3 className="grid-view-element-title">Ужгород</h3>
                <p className="grid-view-elem-description">
                  Контактная информация будет добавлена в ближайшее время.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="grid-view-elem">
                <h3 className="grid-view-element-title">Пятихатки</h3>
                <p className="grid-view-elem-description">
                  Контактная информация будет добавлена в ближайшее время.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="grid-view-elem">
                <h3 className="grid-view-element-title">Соледар</h3>
                <p className="grid-view-elem-description">
                  Контактная информация будет добавлена в ближайшее время.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="grid-view-elem">
                <h3 className="grid-view-element-title">Ирпень</h3>
                <p className="grid-view-elem-description">
                  Контактная информация будет добавлена в ближайшее время.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contacts;
