import React from 'react';
import classNames from 'classnames';

const Cover = ({
  variant = 'default',
  fullPage = false,
  small = false,
  textCenter = false,
  children,
  className,
}) => {
  const headerClassName = classNames(
    'header-back',
    {
      [`header-back-${variant}`]: variant,
      'header-back-full-page': fullPage,
      'header-back-small': small,
    },
    className,
  );
  const pageInfoClassName = classNames('page-info', {
    'helper center': textCenter,
    'page-info-simple': small,
  });

  return (
    <div className={headerClassName}>
      <div className="header-back-container">
        <div className="container">
          <div className={pageInfoClassName}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
